import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Navbar, NavbarBrand, Nav, NavItem } from 'reactstrap'
import AccountDropdown from './AccountDropdown'
import { withTheme } from 'styled-components'
import MenuIcon from 'images/menu.svg'
import DefaultLogo from 'images/logo-acrobatiq.svg'
import CourseSwitchIcon from 'images/course-switch.svg'
import BooksIcon from 'images/books.svg'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import buildPagesEndpoints from 'utils/buildPagesEndpoints'
import { StoreContext } from 'store'

const NavMain = (props) => {
  const {
    customerBrandData,
    openMenuButtonRef,
    theme,
    toggleMenu,
    courseKey,
    hasBooks,
    hideMenuIcon,
    isDashboardEnabled,
    menuIsOpen,
  } = props
  const navigate = useNavigate()
  const { i18n, t } = useTranslation()
  const bpe = buildPagesEndpoints(i18n)
  const { customer, userAq } = useContext(StoreContext)

  const color = theme.vstui.color.primary

  const navStyle = {
    backgroundColor: color.getShade(7),
  }

  const logoSrc = customerBrandData ? customerBrandData.image || DefaultLogo : DefaultLogo
  const customerName = customerBrandData ? customerBrandData.name : ''
  const isMyCoursesPageEnabled =
    customerBrandData && 'myCourses' in customerBrandData
      ? customerBrandData.myCourses
      : customer && customer.myCourses
  const isUserProfileEnabled =
    customerBrandData && 'userProfile' in customerBrandData
      ? customerBrandData.userProfile
      : customer && customer.userProfile
  const isLogoutLinkEnabled =
    customerBrandData && 'enableLogoutLink' in customerBrandData
      ? customerBrandData.enableLogoutLink
      : customer && customer.enableLogoutLink
  const goToCourse = (e) => {
    e.preventDefault()
    navigate(bpe.coursePageUrl({ courseKey }))
  }

  return (
    <Navbar className="main-nav d-block" style={navStyle}>
      {!hideMenuIcon && (
        <button
          type="button"
          aria-label={t('nav.openMenuButton')}
          className="navbar-button float-left"
          onClick={toggleMenu}
          ref={openMenuButtonRef}
          aria-expanded={menuIsOpen}
        >
          <img src={MenuIcon} alt="" />
        </button>
      )}
      <NavbarBrand
        href="#"
        onClick={goToCourse}
        title={t('nav.logoClickAction')}
        className="float-left"
      >
        <img src={logoSrc} alt={customerName} className="main-nav__customer-logo" />
      </NavbarBrand>
      <div className="main-nav__left float-right">
        <Nav>
          {userAq && userAq.is_impersonate_or_review && (
            <NavItem className="d-none d-md-inline">
              <div className="nav-text-item">
                {`${t('nav.vswLabel')}: `}
                <strong className="ml-1">{`${userAq.first_name} ${userAq.last_name}`}</strong>
                <a
                  href={bpe.exitViewStudentWorkUrl({}, true) + `${window.location}`}
                  title={t('nav.vswIconLabel')}
                  className="navbar-button nav-link"
                >
                  <VisibilityOff className="button-icon" />
                </a>
              </div>
            </NavItem>
          )}
          {hasBooks && (
            <NavItem className="d-none d-md-inline">
              <Link
                to={bpe.courseBooksUrl({ courseKey })}
                title={t('nav.accountMenu.courseBooks')}
                className="navbar-button nav-link"
              >
                <img src={BooksIcon} alt={t('nav.courseSwitchIcon')} />
              </Link>
            </NavItem>
          )}
          {isMyCoursesPageEnabled && (
            <NavItem className="d-none d-md-inline">
              <Link
                to={bpe.coursesPageUrl()}
                title={t('nav.accountMenu.mycourses')}
                className="navbar-button nav-link"
              >
                <img src={CourseSwitchIcon} alt={t('nav.courseSwitchIcon')} />
              </Link>
            </NavItem>
          )}
          <AccountDropdown
            courseKey={courseKey}
            isDashboardEnabled={isDashboardEnabled}
            isUserProfileEnabled={isUserProfileEnabled}
            isMyCoursesPageEnabled={isMyCoursesPageEnabled}
            isLogoutLinkEnabled={isLogoutLinkEnabled}
          />
        </Nav>
      </div>
    </Navbar>
  )
}

NavMain.defaultProps = {
  hideMenuIcon: false,
}

NavMain.propTypes = {
  courseKey: PropTypes.string,
  customerBrandData: PropTypes.object,
  hasBooks: PropTypes.bool,
  hideMenuIcon: PropTypes.bool,
  isDashboardEnabled: PropTypes.bool,
  menuIsOpen: PropTypes.bool,
  openMenuButtonRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  theme: PropTypes.object,
  toggleMenu: PropTypes.func,
}

export default withTheme(NavMain)
