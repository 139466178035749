import React, { useContext, useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { PLATFORM_HOST, USER_ROLES } from 'utils/constants'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import { StoreContext } from 'store'
import buildPagesEndpoints from 'utils/buildPagesEndpoints'
import { useKeypress } from 'utils/customHooks'
import AccountIcon from './AccountIcon'

const AccountDropdown = ({
  courseKey,
  isDashboardEnabled,
  isUserProfileEnabled,
  isMyCoursesPageEnabled,
  isLogoutLinkEnabled,
}) => {
  const { t, i18n } = useTranslation()
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const { course, userAq } = useContext(StoreContext)
  const node = useRef()
  const bpe = buildPagesEndpoints(i18n)
  const isInstructor = course?.enrollment?.role === USER_ROLES.INSTRUCTOR
  const isAdmin = !!userAq && userAq?.is_admin

  const shouldDisplaySettings =
    courseKey && course?.enableInstructorSettings && (isInstructor || isAdmin)

  const toggle = () => setDropdownOpen((prevState) => !prevState)

  const handleClick = (e) => {
    if (!node.current.contains(e.target)) {
      // outside click
      setDropdownOpen(false)
    }
  }

  const handleBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      // Not triggered when swapping focus between children
      setDropdownOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)

    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [])

  useKeypress('Escape', () => {
    // close the tooltip on pressing 'Escape'
    setDropdownOpen(false)
  })

  return (
    <li
      className={classnames({ 'dropdown nav-item': true, show: dropdownOpen })}
      ref={node}
      onBlur={handleBlur}
    >
      <button
        type="button"
        aria-label={t('nav.accountMenu.menu')}
        aria-expanded={dropdownOpen ? 'true' : 'false'}
        className="account-dropdown navbar-button nav-link"
        onClick={toggle}
      >
        <AccountIcon aria-hidden="true" />
      </button>
      <div
        tabIndex="-1"
        aria-hidden={dropdownOpen ? 'false' : 'true'}
        className={classnames({ 'dropdown-menu dropdown-menu-right': true, show: dropdownOpen })}
      >
        {isMyCoursesPageEnabled && (
          <Link to={bpe.coursesPageUrl()} className="dropdown-item d-md-none">
            {t('nav.accountMenu.mycourses')}
          </Link>
        )}
        {isUserProfileEnabled && (
          <Link to={bpe.profilePageUrl()} className="dropdown-item">
            {t('nav.accountMenu.account')}
          </Link>
        )}
        {shouldDisplaySettings && (
          <Link to={bpe.settingsPageUrl({ courseKey })} className="dropdown-item">
            {t('nav.accountMenu.settings')}
          </Link>
        )}
        {courseKey && (
          <a
            className="dropdown-item"
            href={bpe.gradebookPageUrl({ courseKey })}
            target="_blank"
            rel="noopener noreferrer"
          >
            {isInstructor ? t('nav.instructorMenu.gradebook') : t('nav.accountMenu.myScores')}
          </a>
        )}
        {isInstructor && (
          <>
            {isDashboardEnabled && (
              <a
                className="dropdown-item"
                href={bpe.dashboardPageUrl({ courseKey }, true)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('nav.instructorMenu.dashboard')}
              </a>
            )}
          </>
        )}
        {courseKey && (
          <a
            href={bpe.studentWorkPageUrl({ courseKey })}
            target="_blank"
            className="dropdown-item"
            rel="noopener noreferrer"
          >
            {t('nav.accountMenu.studentWork')}
          </a>
        )}
        <a
          href={`${PLATFORM_HOST}/help`}
          target="_blank"
          className="dropdown-item"
          rel="noopener noreferrer"
        >
          {t('nav.accountMenu.help')}
        </a>
        {isLogoutLinkEnabled && (
          <a
            href={bpe.logout({}, true) + `?continue=${window.location}`}
            data-cy="logout"
            className="dropdown-item"
          >
            {t('nav.accountMenu.signout')}
          </a>
        )}
      </div>
    </li>
  )
}

AccountDropdown.propTypes = {
  courseKey: PropTypes.string,
  isDashboardEnabled: PropTypes.bool,
  isUserProfileEnabled: PropTypes.bool,
  isMyCoursesPageEnabled: PropTypes.bool,
  isLogoutLinkEnabled: PropTypes.bool,
}

export default AccountDropdown
